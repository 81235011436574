import {Injectable} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EventIdAndPerfCode} from '@biletix/core/models/state/event-perf-code.model';
import {EventProfileType} from '@biletix/core/common/enums/event-profile-type.enum';
import {ShoppingItem} from '@biletix/core/models/shopping-cart/shopping-item.model';
import {TicketSaleService} from '@biletix/core/services/ticket-sale.service';
import {Upsell} from '@biletix/core/models/event/upsell.model';
import {BehaviorSubject, forkJoin, Observable, ReplaySubject, Subject} from 'rxjs';
import {PriceInfo} from '@biletix/core/models/event/price-info.model';
import {ProfileInfo} from '@biletix/core/models/event/profile-info.model';
import {ShoppingCartState} from '@biletix/core/models/state/shopping-cart-state.model';
import {ShoppingStateItem} from '@biletix/core/models/state/shopping-state-item.model';
import {EventDetail} from '@biletix/core/models/event/event-detail.model';
import {FieldConfig} from '@biletix/core/models/base/field-config.model';
import {ImageUrlManager} from '@biletix/core/common/helper/image-url-manager';
import {TicketButtonState} from '@biletix/core/common/enums/ticket-button-state.enum';
import {UpsellStateItem} from '@biletix/core/models/state/upsell-state-item.model';
import {CustomTicketFieldConfig} from '@biletix/core/models/event/custom-ticket-field-config.model';
import {CustomValidators} from '@biletix/core/common/validators/custom-validators';
import {BtxPerformance} from '@biletix/core/models/event/performance.model';
import {BtxPerformanceListItem} from '@biletix/core/models/event/performance-list-item.model';
import {environment} from "@biletix/env";
import {NotificationService} from "@biletix/core/services/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {ShoppingItemSeatInfo} from "@biletix/core/models/shopping-cart/shopping-item-seat-info.model";
import {Block} from "@biletix/core/models/event/block.model";
import {EventService} from "@biletix/core/services/event.service";

export interface ConcessionSelectChange {
  itemId: number;
  quantity?: number;
  emitEvent: boolean;
  onlySelf?: boolean;
  shoppingStateItem?: ShoppingStateItem;
}

@Injectable()
export class PerformancePageStateService {
  ticketFormGroup!: FormGroup;
  ticketProtectionFormGroup!: FormGroup;
  upsellFormGroup!: FormGroup;
  basketButtonState?: TicketButtonState;

  private _currentEventDetail: EventDetail | any;
  private _performanceListItem: BtxPerformanceListItem | any;
  private _shoppingCartState: ShoppingCartState | any;
  private _currentEventCodeAndPerfCode!: EventIdAndPerfCode;

  private _currentPerformance: BtxPerformance | any;

  private _shoppingCartStateChange: BehaviorSubject<ShoppingCartState>;
  shoppingCartStateChange: Observable<ShoppingCartState>;

  private _priceCategoryChange: ReplaySubject<PriceInfo | undefined> =
    new ReplaySubject<PriceInfo | undefined>(1);
  priceCategoryChange: Observable<PriceInfo | undefined>;

  private _blockChange: ReplaySubject<Block|undefined> = new ReplaySubject<Block|undefined>(1);
  blockChange: Observable<Block|undefined>;

  private _concessionQuantityChange: Subject<ConcessionSelectChange> =
    new Subject<ConcessionSelectChange>();
  concessionQuantityChange: Observable<ConcessionSelectChange>;

  private _addToBasketButtonClicked = new Subject<TicketButtonState>();
  basketButtonClickChange: Observable<TicketButtonState>;

  private _deleteSeatButtonClicked = new Subject<{ item:ShoppingStateItem, onHold:boolean }>();
  deleteSeatButtonClickChange: Observable<{ item:ShoppingStateItem, onHold:boolean }>;

  private _customerSelectedTpCheckChange: BehaviorSubject<boolean| undefined> = new BehaviorSubject<boolean| undefined>(undefined);
  customerSelectedTpCheckChange: Observable<boolean|undefined> = this._customerSelectedTpCheckChange.asObservable();

  constructor(
    private fb: FormBuilder,
    private ticketSaleService: TicketSaleService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private eventService:EventService
  ) {
    this._shoppingCartState = new ShoppingCartState();
    this._shoppingCartStateChange = new BehaviorSubject<ShoppingCartState>(
      this._shoppingCartState
    );
    this.shoppingCartStateChange = this._shoppingCartStateChange.asObservable();

    //price categor change
    this.priceCategoryChange = this._priceCategoryChange.asObservable();

        this.blockChange = this._blockChange.asObservable();

        //quantity change
        this.concessionQuantityChange = this._concessionQuantityChange.asObservable();

    //add to basket button
    this.basketButtonClickChange =
      this._addToBasketButtonClicked.asObservable();

    //delete seat button
    this.deleteSeatButtonClickChange =
      this._deleteSeatButtonClicked.asObservable();
  }

  emitShoppingCartStateChangedEvent(shoppingCartState: ShoppingCartState) {
    this._shoppingCartStateChange.next(shoppingCartState);
  }

  setCurrentEventDetail(eventDetail: EventDetail) {
    this._currentEventDetail = eventDetail;
  }

  getEventDetail(): EventDetail {
    return this._currentEventDetail;
  }

  public getEventUpsellItems(): Upsell[] {
    if (this._currentEventDetail) {
      return this._currentEventDetail.btxUpsells ?? [];
    }
    return [];
  }

  public getEventCustomTicketFieldConfigs(): FieldConfig[] {
    return this.mapToFieldConfigs(this._currentEventDetail?.customTicketFields);
  }

  private mapToFieldConfigs(
    customTicketFields: CustomTicketFieldConfig[]
  ): FieldConfig[] {
    if (customTicketFields && customTicketFields.length > 0) {
      return customTicketFields.map((field) => {
        const fieldConfig: FieldConfig = <any>field;
        fieldConfig.name = 'value';
        fieldConfig.validations = CustomValidators.createValidations(field);
        return fieldConfig;
      });
    }
    return [];
  }

  getEventSeatPlanImage(): string {
    return ImageUrlManager.createSeatPLanImage(
      this._currentEventDetail?.seatPlanImage ?? ''
    );
  }

  setPerformanceListItemDetail(performanceListItem: BtxPerformanceListItem) {
    this._performanceListItem = performanceListItem;
  }

  getPerformanceListItemDetail(): BtxPerformanceListItem {
    return this._performanceListItem;
  }

  setCurrentPerformance(performance: BtxPerformance) {
    this._currentPerformance = performance;
  }

  public getCurrentPerformance(): BtxPerformance {
    return this._currentPerformance;
  }

  /**
   *
   */
  public createParentFormGroup(): FormGroup {
    if (!this.ticketFormGroup) {
      this.ticketFormGroup = this.fb.group({
        profile: [[], Validators.required],
        performance: undefined,
        block: ['', Validators.required],
        priceInfo: [null, Validators.required],
        validationFields: this.fb.array([]),
        concessions: this.fb.array([]),
        ticketFields: this.fb.array([]),
        errorMessage: undefined,
        row: undefined,
        seat: undefined,
        profiles: [],
        priceMap: new Map<number, PriceInfo[]>(),
        performanceProfileMap: new Map<number, BtxPerformance>()
      });
    }
    return this.ticketFormGroup;
  }

  public createUpsellFormGroup(): FormGroup {
    this.upsellFormGroup = this.fb.group({
      upsellInputs: this.fb.array([]),
    });

    return this.upsellFormGroup;
  }

  public createTicketProtectionFormGroup(tpCheck: boolean, isCustomerSelectTpCheck: boolean): FormGroup {

    this.ticketProtectionFormGroup = this.fb.group({
      ticketProtectionCheck: !isCustomerSelectTpCheck ? [] : [tpCheck.toString()],
    });

    this._shoppingCartState.setTicketProtectionSelection(tpCheck); //shoppingCartState'in ilk halini set etmek için

    return this.ticketProtectionFormGroup;
  }

  public resetTicketFormGroup(...properties: string[]) {
    properties.forEach((prop) => this.ticketFormGroup.get(prop)?.reset());
  }

  public resetTicketFormGroupValidationFieldsFormControl() {
    this.ticketFormGroup.removeControl('validationFields');
    this.ticketFormGroup.addControl('validationFields', this.fb.array([]));
  }

  get parentFormValue(): any {
    if (!this.ticketFormGroup) {
      return null;
    }
    return this.ticketFormGroup.value;
  }

  public getCurrentProfile(): ProfileInfo | any {
    if (!this.parentFormValue) return null;

    return this.parentFormValue.profile;
  }

  public getCurrentPriceInfo(): PriceInfo | any {
    if (!this.parentFormValue) return null;

    return this.parentFormValue.priceInfo;
  }

  public getBasePrice(): number {
    let priceInfo = this.getCurrentPriceInfo();
    if (priceInfo) {
      return priceInfo.value;
    }
    return 0;
  }

  public setEventCodeAndPerfCode(model: EventIdAndPerfCode): void {
    this._currentEventCodeAndPerfCode = model;
  }

  public get currentEventPerfCode(): EventIdAndPerfCode {
    return this._currentEventCodeAndPerfCode;
  }

  getActiveEventCode(): string {
    return this._currentEventCodeAndPerfCode.eventCode;
  }

  getActiveEventPerfCode(): string {
    return this._currentEventCodeAndPerfCode.perfCode;
  }

  /**
   *TODO: Hangi profiller birlikte satılacak. Şuan localden kontrol ediliyor. Servise bağlanması lazım
   * @param profileType
   */
  public validateShoppingCartProfile(profileType: EventProfileType) {
    let shoppingCart = this.ticketSaleService.getLocalShoppingCart();

    if (
      shoppingCart == null ||
      shoppingCart.shoppingItems.length == 0 ||
      EventProfileType[EventProfileType.PUBLIC] == profileType
    )
      return true;

    const otherShoppingItems = shoppingCart.shoppingItems //
      .filter(
        (item: ShoppingItem) =>
          EventProfileType[EventProfileType.PUBLIC] != item.profileType
      );

    //Genel Şatış dışında sadece bir profilden satışa izin verilecek.
    return (
      otherShoppingItems.length == 0 ||
      otherShoppingItems.some(
        (item: ShoppingItem) => item.profileType == profileType
      )
    );
  }

  /**
   * TODO: Server tarafında bu değer kontrol edilmeli. Burası daha sonra kontrol edilmelidir.
   * Shopping cart üzerinde bu performans ta item varsa geri kalan maxTicket alanı set edilmeli.
   *
   *
   * @param profileId
   * @param maxTicket
   */
  getMaxTicketCountForActivePerformance() {
    let performance: BtxPerformance = this.getCurrentPerformance();

    if (performance) {
      let basketCount =
        this.ticketSaleService.getSelectedTicketCountByActivePerformance(
          this.getActiveEventCode(),
          this.getActiveEventPerfCode()
        );

      const maxTicket = performance.maxTicket?performance.maxTicket:9;

      let value = maxTicket - basketCount;

      return value > 0 ? value : 0;
    }
    return 0;
  }

  /**
   *
   * @param itemId
   * @param quantity
   */

  getStateQuantity(): number {
    let totalStateQuantity:number = 0;
    this.shoppingCartState.shoppingStateItems.forEach(item => {
      totalStateQuantity += item.quantity;
    });
    return totalStateQuantity;
  }

  getStateQuantityByPerformance(performanceId:number) {
    let totalStateQuantity = 0;
    this.shoppingCartState.shoppingStateItems.filter(item => item.performanceId === performanceId).forEach(item => {
      totalStateQuantity += item.quantity;
    });
    return totalStateQuantity;
  }

  getMaxTicketQuantity() {
    return environment.maxTicketQuantity
      - this.ticketSaleService.getSelectedTicketCountByActivePerformance(this.getActiveEventCode(), this.getActiveEventPerfCode())
      - this.getStateQuantity();
  }

  getMaxTicketQuantityByPerf(performance:BtxPerformance, profile:ProfileInfo) {
    return performance.maxTicket
      - this.ticketSaleService.getSelectedTicketCountByActivePerformanceByProfileId(this.getActiveEventCode(), this.getActiveEventPerfCode(), profile.profileId)
      - this.getStateQuantityByPerformance(performance.id);
  }

  getKeyForSeatControl(profileId: number, seat:ShoppingItemSeatInfo, rowLevel:boolean):string {
    return profileId + (rowLevel?(seat.blockCode + seat.rowNo):"");
  }

  getCartItemCountByProfileId(rowLevel:boolean) {
    const profileIdCountMap = new Map<string,number>();
    this.ticketSaleService.getLocalShoppingCart().shoppingItems.forEach(item => {
      item.seats.forEach((seat:ShoppingItemSeatInfo) => {
        const key = this.getKeyForSeatControl(item.profileId, seat, rowLevel);
        profileIdCountMap.set(key, (profileIdCountMap.has(key))?profileIdCountMap.get(key)!+1:1)
      })
    });
    return profileIdCountMap;
  }
  validateTicketQuantity(quantity:number, performance:BtxPerformance, profile:ProfileInfo):number {
    const maxTicketByPerf = this.getMaxTicketQuantityByPerf(performance, profile);
    const maxTicket = this.getMaxTicketQuantity()
    let resultQuantity = Math.min(maxTicket,maxTicketByPerf);
    if (quantity > maxTicketByPerf) {
      this.notificationService.info(this.translate.instant("shared.info.seatProfileMaxCountInfo", {profileName:profile.profileName, count:performance.maxTicket}));
      resultQuantity = 0;
    }

    if(quantity >  maxTicket){
      this.notificationService.info(this.translate.instant("shared.info.seatMaxCountInfo", {count:environment.maxTicketQuantity}));
      resultQuantity = 0;
    }

    return resultQuantity
  }

  validateStateItems(profile:ProfileInfo) {
    let isValid = true;
    if (profile.profileType === EventProfileType.CREDITCARD) {
      const selectedProfiles = this.shoppingCartState.shoppingStateItems.map(item => item.profile);
      selectedProfiles.forEach(selectedProfile => {
        if (selectedProfile.profileType === EventProfileType.CREDITCARD && selectedProfile.profileId !== profile.profileId) {
          this.notificationService.error(this.translate.instant("shared.error.bankProfileRule"));
          isValid = false;
        }
      });
    }
    return isValid;
  }

  public saveShoppingStateItemChanges(itemId: number, quantity?: number, oldItem?:ShoppingStateItem) {

    let shoppingStateItem: ShoppingStateItem|undefined = oldItem;

    //add item
    const formValue = this.ticketFormGroup.value;
    const profile = Array.isArray(formValue.profile)? formValue.profile[formValue.profile.length -1]:formValue.profile;
    const concessionItem = formValue.concessions[itemId];
    const blockCode = formValue.block.code;
    const blockName = formValue.block.description;
    const priceInfo = formValue.priceInfo;

    if (!shoppingStateItem) {
      if (formValue.row && formValue.seat) {
        shoppingStateItem = this.shoppingCartState.getShoppingStateItem(blockCode, formValue.row, formValue.seat);
      } else {
        shoppingStateItem = this.shoppingCartState.getShoppingStateItemConcessionAndPriceInfo(concessionItem.concessionName, priceInfo.description, profile.profileName, blockCode);
      }
    }


    if (quantity && quantity > 0 && this.validateTicketQuantity(quantity,formValue.performance,profile) <= 0) return;
    if (!this.validateStateItems(profile))return;

    if (shoppingStateItem && quantity && (quantity === 0 || (shoppingStateItem.quantity + quantity)=== 0)) {
      this.shoppingCartState.removeShoppingItem(shoppingStateItem);
    } else if (shoppingStateItem && !!quantity) {
      this.changeQuantity(shoppingStateItem, shoppingStateItem.quantity + quantity);
    } else {
      if (shoppingStateItem) {
        this.shoppingCartState.removeShoppingItem(shoppingStateItem);
        quantity = shoppingStateItem.quantity;
        shoppingStateItem = undefined;
      }
      shoppingStateItem = {
        itemId: itemId,
        blockName: blockName,
        blockCode:blockCode,
        profile:profile,
        categoryName: priceInfo.description,
        performanceId: formValue.performance.id,
        quantity: quantity!,
        row:formValue.row,
        seat:formValue.seat,
        priceInfo:priceInfo,
        servicePrice: concessionItem.servicePrice,
        totalPrice: quantity! * concessionItem.totalPrice,
        price: concessionItem.pricePerSeat,
        concessionName: concessionItem.concessionName,
        concessionCode:concessionItem.code,
        priceLevel:concessionItem.priceLevel,
        ticketFields:concessionItem.ticketFields,
        zeroPriceEnabled: this.getCurrentPerformance().zeroPriceEnabled

      };
      let eventDetail = this.getEventDetail();
          let shoppingCart = this.ticketSaleService.getLocalShoppingCart();

          this.shoppingCartState.setDigitalTicketCost(false,350);

          const summaryItem = this.shoppingCartState.getShoppingStateItemConcessionAndPriceInfo(shoppingStateItem.concessionName, shoppingStateItem.priceInfo.description,shoppingStateItem.profile.profileName, shoppingStateItem.blockCode);
          if (summaryItem) {
            this.changeQuantity(summaryItem, summaryItem.quantity + shoppingStateItem.quantity)
          } else {
            this.shoppingCartState.addShoppingItem(shoppingStateItem);
          }
        }
        return shoppingStateItem;
  }

  setPriceByProfile(profileInfos: ProfileInfo[], definitionCode: string | undefined) {
    return new Observable<any>(observable => {
      if (this.ticketFormGroup.value.priceMap.size > 0) {
        observable.next(this.ticketFormGroup.value.priceMap);
      } else {
        const priceMap = new Map<number, PriceInfo[]>();
        this.eventService.getPriceByProfiles(this.getActiveEventCode(), this.getActiveEventPerfCode(), definitionCode).subscribe(result => {
          Object.keys(result).forEach(profileId => {
            const priceInfoLevels = result[Number(profileId)]!;
            priceMap.set(Number(profileId), priceInfoLevels);
          });

          this.ticketFormGroup.patchValue({
            priceMap: priceMap,
            profiles: profileInfos,
          });
          observable.next(result);
        });
      }
    });

  }

  getPerformanceByProfile(profileInfos:ProfileInfo[]):Observable<BtxPerformance[]> {
    return new Observable<any>(observable => {
      if (this.ticketFormGroup.value.performanceProfileMap.size > 0) {
        observable.next(this.ticketFormGroup.value.performanceProfileMap);
      } else {
        const performanceProfileMap: Map<number, BtxPerformance> = new Map<number, BtxPerformance>();

        forkJoin(
          profileInfos.map((profile: ProfileInfo) => {
            return this.eventService.getPerformanceByProfileIdAndPerfCode(profile.profileId, this.getActiveEventPerfCode());
          })
        ).subscribe(performanceList => {
          performanceList.forEach((performance, index) => {
            performanceProfileMap.set(profileInfos[index].profileId, performance);
          });

          this.ticketFormGroup.patchValue({
            performanceProfileMap: performanceProfileMap
          });

          observable.next(performanceList);
        });
      }
    });

  }

  changeQuantity(shoppingStateItem:ShoppingStateItem, quantity:number) {
    if (this.shoppingCartState.shoppingStateItems && this.shoppingCartState.shoppingStateItems.length > 0) {
      let index: number = this.shoppingCartState.shoppingStateItems.indexOf(shoppingStateItem);
      if (shoppingStateItem.quantity + quantity > 0) {
        if (index >= 0) {
          this.shoppingCartState.shoppingStateItems[index].totalPrice = quantity * (this.shoppingCartState.shoppingStateItems[index].totalPrice!/this.shoppingCartState.shoppingStateItems[index].quantity);
          this.shoppingCartState.shoppingStateItems[index].quantity = quantity;
        }
      } else {
        this.shoppingCartState.removeShoppingItem(shoppingStateItem);
        this.setShoppingCartState();
      }

    }
  }

  /**
   *
   * @param upsellStateItem
   */
  public addOrUpdateUpsellStateItem(upsellStateItem: UpsellStateItem) {
    let stateItem = this.shoppingCartState.getUpsellStateItemByItemId(
      upsellStateItem.itemId
    );
    if (stateItem) {
      stateItem.quantity = upsellStateItem.quantity;
      stateItem.totalPrice = upsellStateItem.totalPrice;
    } else {
      this.shoppingCartState.addUpsellStateItem(upsellStateItem);
    }
  }

  /**
   *
   * @param itemId
   */
  public removeUpsellItem(itemId: number) {
    this.shoppingCartState.removeUpsellStateItem(itemId);
  }

  /**
   *
   */
  get shoppingCartState(): ShoppingCartState {
    return this._shoppingCartState;
  }

  /**
   *
   */
  public clearShoppingStateItems(): void {
    this.shoppingCartState.shoppingStateItems = [];
  }

  /**
   *
   */
  public clearStateData(): void {
    //
    if (this.ticketFormGroup) {
      this.ticketFormGroup.get('priceInfo')?.reset();
    }
    this._shoppingCartState.shoppingStateItems = [];
    this._shoppingCartState.upsellStateItems = [];
    this.clearAllUpsellQuantity();
  }

  private clearAllUpsellQuantity(): void {
    if (this.upsellFormGroup) {
      let upsellInputs: FormArray = this.upsellFormGroup.get(
        'upsellInputs'
      ) as FormArray;

      upsellInputs?.controls.forEach((group) => {
        group.patchValue({
          quantity: 0,
        });
      });
    }
  }

  //Subscriptions
  public onChangePriceCategory(priceInfo: PriceInfo | undefined): void {
    //this.clearShoppingStateItems();
    this._priceCategoryChange.next(priceInfo);
  }

    //Subscriptions
    public onChangeBlock(block: Block|undefined): void {
        //this.clearShoppingStateItems();
        this._blockChange.next(block);
    }

    /**
     *
     * @param state
     */
  public onChangeConcessionQuantityValue(state: ConcessionSelectChange) {
    let shoppingStateItem:ShoppingStateItem|undefined;
    if (state) {


      shoppingStateItem = this.saveShoppingStateItemChanges(state.itemId, state.quantity, state.shoppingStateItem);


      //emit change value
      if (state.emitEvent) {
        this._concessionQuantityChange.next(state);
      }

      this.emitShoppingCartStateChangedEvent(this.shoppingCartState);
    }
    return shoppingStateItem;
  }

  /**
   *
   * @param buttonEvent
   */
  public onClickBasketButton(
    buttonEvent: TicketButtonState = TicketButtonState.ADD_TO_BASKET
  ) {
    this._addToBasketButtonClicked.next(buttonEvent);
  }

  public onDeleteSeatButton(
    buttonEvent: ShoppingStateItem,
    onHold?:boolean
  ) {
    this._deleteSeatButtonClicked.next({item:buttonEvent, onHold:!!onHold});
  }

  public removeShoppingItem(shoppingStateItem:ShoppingStateItem, onHold?:boolean) {
    this.onDeleteSeatButton(shoppingStateItem,onHold);
    this.shoppingCartState.removeShoppingItem(shoppingStateItem);
    this.setShoppingCartState();
  }

  /**
   * Block dress type =3  ayakta bir block olduğunu göstermektedir. Koltuk seçimi yapılamaz.
   * ismAllowed=false koltuk seçimine izin yok
   */
  public isSeatSelection(): boolean {
    const currentBlock = this.ticketFormGroup.value.block;
    return (
      currentBlock &&
      currentBlock.dressType !== 3 &&
      this._currentEventDetail?.ismAllowed
    );
  }

  /**
   *
   */
  public getSelectedTicketCount(): number {
    return this.shoppingCartState?.ticketCount ?? 0;
  }

  public isTicketSelected(): boolean {
    return this.getSelectedTicketCount() > 0;
  }

  public getSelectedUpsellCount(): number {
    return this.shoppingCartState?.upsellCount ?? 0;
  }

  public isUpsellSelected() {
    return this.getSelectedUpsellCount() > 0;
  }

  public setCustomerSelectedTpCheck(selected: boolean) {
    this._customerSelectedTpCheckChange.next(selected);
  }

  public setShoppingCartState() {
    this._shoppingCartStateChange.next(this.shoppingCartState);
  }
}
